.header{
  text-align: center;
  
 
}
.header h1{
  font-size: 42px;
  position: relative;
  margin: 0;
  margin-bottom: 30px;
}
.LOGO{
  height: 75px;
margin-left: 20px;
}
.tableA{
  
  padding: 20px;
  padding-left: 0;
  padding-top: 0;
}
.table1{
  border: none;
  width: 100%;
  border-collapse: collapse;
}
.tr1{
height: 250px;
background: linear-gradient(to right, #ffffff,rgb(190, 245, 250));
}
.tr2{
  height: 250px;
  background: linear-gradient(to right, #ffffff,#f6c05a);
}
.tr3{
  height: 250px;
  background: linear-gradient(to right, #ffffff, #0000ff);
}
.td1{
  width: 50%;
  text-align: center;
}
.td2{
  text-align: center;
}
.cubiio{
  max-width: 100%;
  max-height: 400px;
  transition: filter 0.3s;
  
}
.oceanIOT{
  max-width: 100%;
  height: 400px;
  transition: filter 0.3s;
}
.image-container {
  position: relative;
  display: inline-block;
}

.text-overlay {
  font-size: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  color: white;

  transition: opacity 0.3s; /* 添加過渡效果 */
}
.image-container:hover .cubiio {
  filter: brightness(50%); /* 調整亮度以使圖片變暗 */
  transform: scale(1.005); /* 放大圖片 */
}
.image-container:hover .oceanIOT {
  filter: brightness(50%); /* 調整亮度以使圖片變暗 */
  transform: scale(1.005); /* 放大圖片 */
}

.image-container:hover .text-overlay {
  opacity: 1; /* 使文本顯示 */
}
h3{
  text-align: left;
}
.href1{
  text-decoration: none;
  color: rgb(74, 69, 223);
  font-size: 36px;
}
.footer{
  height: 50px;
  text-align: center;
  margin-left: 10px;
}
.number{
  position: relative;
  font-size: 12px;
}

@media (max-width: 768px) {
 .tableA{
  width: 100%;
 }
  .table1{
    height: 50rem;
    width: 400px;
    
  }

  .td1{
    display: flex;
  }
  .td2{
    width: 23rem;
    font-size: 0.5rem;
    display: flex;
    flex-direction: column;
  }
  .td2-h1{
    font-size: 20px;
    font-weight:bolder;
    color: #5e0232;
  }
.td2-h3{
  font-size: 14px;
  margin-left: 10px;
}
 
  .image-container{
    width: 50rem;
  }
.oceanIOT{
  max-width: 100%;
}
}
